import React from "react"
import styles from "./card.module.scss"

export default function Card({ icon, title, children }) {
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        {icon}
        <span>{title}</span>
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  )
}
