import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "../../components/container"
import Card from "../../components/card"
import Button from "../../components/button"
import { Mail, MapPin, Phone, Globe } from "../../components/icons"
import SocialIcons from "../../components/social-icons"
import Seperator from "../../components/seperator"
import Title from "../../components/title"
import { Input, Textarea } from "../../components/form-elements"
import styles from "./Iletisim.module.scss"

export default function Iletisim() {
  const [submitText, setSubmitText] = React.useState("Gönder")
  return (
    <Layout>
      <SEO title="İletişim" />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12032.767839961327!2d28.659786581386985!3d41.06479646074291!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6a7cd320077dfbcf!2s%C3%96zel+Civata!5e0!3m2!1str!2str!4v1488538132267"
        width="100%"
        height="450"
        frameborder="0"
        className={styles.map}
        title="harita"
      ></iframe>
      <Container className={styles.container}>
        <div className={styles.cards}>
          <Card icon={<MapPin />} title="Adres">
            Tem 34 Sanayi Sitesi 2. Etap 1573. Sk. Akçaburgaz Mah. B4 No: 4{" "}
            <br />
            Esenyurt/İstanbul
          </Card>

          <Card icon={<Phone />} title="Tel/Fax">
            <p>
              <strong>Tel:</strong> +90 (212) 672 13 80
            </p>
            <p>
              <strong>Fax:</strong> +90 (212) 672 13 81
            </p>
          </Card>

          <Card icon={<Mail />} title="E-Posta">
            <a href="mailto:info@ozelcivata.com">info@ozelcivata.com</a>
          </Card>

          <Card icon={<Globe />} title="Sosyal Medya">
            <SocialIcons className={styles.socialIcons} />
          </Card>
        </div>
        <Seperator />
        <section className={styles.contact}>
          <header>
            <Title headingLevel="h2">Mesaj Gönderin</Title>
            <Title headingLevel="p">
              Sunduğumuz hizmetler ile ilgili sorularınız , istek ve
              önerileriniz için aşağıdaki formu kullanabilirsiniz.
            </Title>
          </header>
          <form
            className={styles.form}
            name="Iletisim Formu"
            netlify-honeypot="bot-field"
            method="POST"
            action="/iletisim/tesekkurler"
            data-netlify="true"
            onSubmit={e => setSubmitText("Gönderiliyor...")}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="Iletisim Formu" />
            <div>
              <Input
                label="Adınız Soyadınız *"
                type="text"
                name="name"
                required
              />
              <Input
                label="E-Posta Adresiniz *"
                type="email"
                name="email"
                required
              />
              <Input label="Telefon Numaranız" type="text" name="phone" />
              <Input label="Firma Adı" type="text" name="company" />
            </div>
            <div>
              <Input label="Mesaj Konusu" type="text" name="subject" />
              <Textarea label="Mesajınız" cols="40" rows="9" name="message" />
            </div>
            <div>
              <Button type="submit">{submitText}</Button>
            </div>
          </form>
        </section>
      </Container>
    </Layout>
  )
}
